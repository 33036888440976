// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/top.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".icons{position:fixed;right:1.333333vw;bottom:10.666667vw;width:10.666667vw;height:10.666667vw;border-radius:50%;background:rgba(0,0,0,.65)}.icons:hover{background:rgba(0,0,0,.85)}.icons-top{display:block;width:10.666667vw;height:10.666667vw;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;background-repeat:no-repeat;background-position:50%}@media (orientation:landscape){.icons{right:.880282vw;bottom:7.042254vw}.icons,.icons-top{width:7.042254vw;height:7.042254vw}}", ""]);
// Exports
module.exports = exports;
