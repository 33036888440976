















import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'GoTop'
})
export default class GoTop extends Vue {
  scrollTop = null as any //初始化scrollTop
  visiable = false //默认不显示

  handleScroll() {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    if (this.scrollTop > 500) {
      this.visiable = true
    } else {
      this.visiable = false
    }
  }

  handleScrollTop() {
    let timer = null as any
    const that = this as any
    cancelAnimationFrame(timer)
    timer = requestAnimationFrame(function fn() {
      if (that.scrollTop > 0) {
        that.scrollTop -= 50
        document.body.scrollTop = document.documentElement.scrollTop =
          that.scrollTop
        timer = requestAnimationFrame(fn)
      } else {
        cancelAnimationFrame(timer)
        that.visiable = false
      }
    })
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  }
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
